import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import { useAlternateLangs } from "../../components/Hreflangs";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";


const breadCrumbLevels = {
    Hem: "/",
    "Om mig": "/se/om",
    "SEO Frilansare": "/se/seo-frilansare"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
    // replace with pages x-default
    "/en/seo-freelancer"
);


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="SEO Frilansare för anställning: Profil av M. Kupperschmidt"
                    description="Jag är en frilansande SEO-konsult med många års erfarenhet från byråer, och du kan anlita mig för att få din webbplats till toppen av Googles rankningar. Kontakta mig direkt."
                    lang="se"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="/se/seo-frilansare"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt pratar om sökords svårighet vid SEO-konsulters möte i Köpenhamn, Danmark, oktober 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Anlita en frilansande SEO-konsult</H>
                    <ProfileCard
                        tags={["SEO-innehåll", "Backlinks", "Teknisk SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="SEO-konsult"
                        rate={`från ${monthlySeo}€ per månad`}
                        location="Berlin, Tyskland / fjärrarbete"
                        cta="Kontakta"
                    />
                    <p>Jag är en SEO-frilansare och du kan anlita mig för att få din webbplats till toppen av Googles sökresultat.</p>
                    <p>Jag erbjuder samma tjänst som en SEO-byrå, bara som en oberoende SEO-konsult. Jag tar över planering, genomförande och kodimplementering eller samarbetar med din webbyrå.</p>
                    <p>Tidigare arbetade jag på olika SEO-byråer för företagskunder och e-handelsföretag med sökmotoroptimering dagligen.</p>
                    <p>År 2018 tog jag steget att börja arbeta som frilansande SEO-konsult. Förutom att optimera Googles sökmotorrankningar bygger jag också professionella spårningsuppsättningar med t.ex. Google Analytics och Google Tag Manager.</p>
                    <p>
                        Du kan läsa mina <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">omdömen</a> eller lära dig mer om min karriär på{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            Linkedin.
                        </a>{" "}
                        Min personliga historia och andra detaljer finns på min <Link to="/se/om">om mig-sida</Link>.
                    </p>
                    <br />
                    <br />
                    <H as="h6" style={{ "textAlign": "center" }}>Vilka jag har arbetat med</H>
                    <ImgScreenshot
                        src="about/clients_700px.png"
                        alt="kunder jag har arbetat med"
                        className="article-img"
                    />
                    <br />
                    <br />
                    <br />


                    <H as="h2" style={{ "textAlign": "center" }}>Begär en offert</H>
                    <ContactForm showHeadline={false} formName="ga consultant (SV) - kontaktformulär" />

                    <H as="h2">SEO-tjänster</H>
                    <p>
                        Generellt arbetar jag med alla viktiga SEO-rankingfaktorer för att optimera webbplatser för sökmotorer, precis som
                        vilken SEO-byrå som helst.
                    </p>
                    <p>Här är en kort lista över mina SEO-tjänster:</p>
                    <ul>
                        <li>On-page & Off-page optimering</li>
                        <li>Skalbara backlink-byggstrategier</li>
                        <li>Innehållsskapande på flera språk</li>
                        <li>JavaScript SEO</li>
                        <li>Optimering av laddningstid</li>
                        <li>Teknisk SEO-rådgivning</li>
                        <li>Webbplatsstruktur</li>
                        <li>Accelerated Mobile Pages (AMP)</li>
                        <li>ingen lokal SEO</li>
                    </ul>
                    <p>Även om jag fokuserar på SEO, är jag väl medveten om att det finns andra kraftfulla betalda trafik källor förutom söktrafik, t.ex. Facebook, Twitter eller Google-annonser. Därför implementerar jag mina SEO-tekniker med andra internetmarknadsföringsdiscipliner i åtanke, så att vi kan <b>utnyttja organisk trafik för retargeting</b> i betalda sökkampanjer och sociala mediekampanjer också.</p>
                    <p>Tack vare min kunskap om webbutveckling kan jag också hjälpa dig med webbdesignuppgifter i HTML, CSS och JavaScript eller konfigurationer i Google Tag Manager och Google Analytics.</p>

                    <H as="h2">Timpris och kostnader</H>
                    <p>Mitt timpris för frilansande SEO-rådgivning är <b>{hourlyRate} €</b> netto per timme. De totala kostnaderna för ett SEO-projekt beror på dina egna mål, men också på din nuvarande webbplatsoptimering och dina konkurrenters ansträngningar.</p>
                    <p>Nedan är några påverkande faktorer som bestämmer priset på mitt SEO-rådgivningsarbete:</p>
                    <ul>
                        <li>Totalt antal sidor på domänen</li>
                        <li>Domänens ålder och auktoritet</li>
                        <li>Konkurrensens intensitet</li>
                        <li>Tekniska utmaningar</li>
                        <li>Anpassningsbarhet hos frontend</li>
                        <li>Antal språk vi optimerar för</li>
                        <li>Hur mycket nytt webbplatsinnehåll behöver vi?</li>
                        <li>Vilken linkbuilding-strategi krävs?</li>
                        <li>Finns det andra partners som täcker uppgifter för innehåll eller länkar?</li>
                    </ul>
                    <br />
                    <p>Minimitiden är vanligtvis <b>minst 15 arbetstimmar per månad</b>.</p>
                    <p>Potentiella kunder måste lita på SEO-expertens bedömning av hur stark konkurrensen är, vilken webbplatsoptimering som krävs och vilka möjligheter som finns.</p>
                    <p>Läs vidare nedan där jag förklarar <Link to="/se/seo-frilansare#how-to-hire-the-right-freelancer">hur man anlitar rätt SEO-frilansare</Link>.</p>
                    <p>För att bestämma prissättningen definieras kontraktets omfattning vid projektets start, baserat på ovanstående faktorer.</p>
                    <p>För <b>pågående SEO-optimering</b> är en månatlig projektomfattning baserad på uppgifter och milstolpar. Baserat på timpriset, planerade SEO-insatser och erforderlig arbetstid är SEO-kostnaderna planerade och begränsade för varje månad.</p>
                    <p>Efter en överenskommelse kan jag börja mitt arbete så snart jag får tillgång till webbplatsen och relaterade verktyg.</p>
                    <p>Jag tillhandahåller <b>SEO-rapporter</b> i form av en live-dashboard så att du har full insyn i sökordens rankningar och den övergripande framgången med min SEO-tjänst. Jag betalar själv för en mängd olika SEO-verktyg och inkluderar dem i priset.</p>
                    <p>SEO-kunder kan ibland bara behöva ett <b>litet engångsprojekt</b>. Till exempel en <b>optimering av laddningstid</b>, en <b>teknisk SEO-granskning, konkurrentanalys</b> eller implementering av viktiga landningssidor som <b>AMP-sidor</b>.</p>
                    <p>Sådana mindre projekt är individuellt anpassade och är naturligtvis inte relaterade till ovanstående faktorer.</p>


                    <H as="h2">Fördelar med en SEO-frilansare jämfört med en byrå</H>
                    <p>Som en allmän regel är frilansare en bra passform för kunder som <b>vet vad de vill ha</b>.</p>
                    <p>Vanligtvis är det ett företag som tidigare har arbetat med en byrå och har en kunnig person internt. De behöver mindre handhållning och letar snarare efter pålitlig genomförande. De vill få jobbet gjort med en hög grad av säkerhet utan för många powerpoints och säljtrick.</p>
                    <p>Frilansare levererar precis det. De fokuserar på genomförande och har begränsad tid för kommunikation.</p>
                    <p>Sammanfattningsvis är fördelarna med att arbeta med en frilansare följande:</p>
                    <ul>
                        <li><b>bättre pris</b></li>
                        <li><b>flexibilitet</b> eftersom du kan anlita vid behov</li>
                        <li>fler år av <b>erfarenhet</b></li>
                        <li>mer <b>specialiserade</b> profiler tillgängliga</li>
                        <li><b>kommunikation</b> direkt med specialisten</li>
                    </ul>

                    <H as="h2">Nackdelar</H>
                    <p>Nackdelarna med att arbeta med en frilansare är vanligtvis relaterade till, men inte begränsade till, skalbarhet. I slutändan är det en person du arbetar med som bara har två händer. Med ett team får du mer arbete gjort på kortare tid.</p>
                    <p>Andra potentiella nackdelar är:</p>
                    <ul>
                        <li><b>Begränsningar i skalbarhet:</b> Om du förväntar dig att antalet uppgifter ska öka, kanske en person inte räcker för jobbet.</li>
                        <li><b>Begränsningar på grund av expertis:</b> En byrå kommer att ha en bredare variation av människor tillgängliga för råd.</li>
                        <li><b>Tillförlitlighet:</b> Sjukdom eller oväntade livsstörningar kan bättre kompenseras med ett team till hands.</li>
                        <li><b>Personlighetsmismatch:</b> Om du inte klickar med din frilansare kan du behöva gå vidare och slösad tid. En byrå skulle kunna sätta dig i kontakt med en annan person.</li>
                    </ul>

                    <H as="h2">Hur anställer man rätt frilansare?</H>
                    <p>När man anställer SEO-frilansare är det viktigt att validera erfarenheten och SEO-färdigheterna hos varje profil.</p>
                    <p>Till exempel vill du försäkra dig om att din webbplats teknik matchar frilansarens tidigare projekt. Om du driver en WordPress-webbplats kommer de flesta frilansare att vara bekväma med att arbeta med den. Men om du har en mycket anpassad webbteknik med t.ex. React eller Angular i frontend och Sitecore eller Umbraco i backend, vill du säkerställa att det inte utgör några utmaningar.</p>
                    <p>Individuella SEO-specialister kanske inte är vana vid att genomföra ändringar via Git, men din webbplats kräver det. Så diskutera processen för att genomföra ändringar på webbplatsen för att upptäcka potentiella flaskhalsar.</p>
                    <p>Vissa företag bygger sin webbnärvaro själva med en intern utvecklare, medan andra arbetar med en webbyrå. Detta har konsekvenser för SEO-rådgivning eftersom ändringar antingen kan genomföras direkt eller måste gå via en extern byrå. Detta påverkar kostnaden och den nödvändiga tiden för en SEO-kampanj eftersom fler kommunikationslager är nödvändiga tills en ändring genomförs.</p>
                    <p>Så oberoende SEO-experter måste vara bekväma med den givna uppsättningen av interna och externa team och överväga dess inverkan på den övergripande SEO-strategin.</p>
                    <p>Även tidigare arbetsplatser och var frilansaren har arbetat spelar en roll för att dra slutsatser om arbetsmoral och tillförlitlighet. Tröskeln för att bli SEO-frilansare på Upwork är ganska låg och kanske därför inte levererar rätt kandidat (säger inte att det är omöjligt). Därför hoppas jag kunna erbjuda lite transparens genom att dela min{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            Linkedin-profil
                        </a>{" "}
                        i förväg.
                    </p>
                    <p>När du hittar någon, bör ett enkelt e-postmeddelande eller ifyllt kontaktformulär vara tillräckligt för att initiera ett samtal. En professionell SEO-frilansare tar dock inte varje projekt som hamnar på bordet. Och det är bra för kunder, så ta inte ett avslag personligt.</p>
                    <p>Det finns varierande SEO-strategier för olika nischer och sökordsavsikter. Frilansare vill försäkra sig om att nischens krav och webbplatsens teknik matchar deras expertis.</p>
                    <p>Om de inte gör det bör kunder vara skeptiska. Om du inte behöver en någorlunda standard SEO-uppgift utförd, vill du hitta en SEO-frilansare som är expert på ditt fall.</p>
                    <p>Efter att projektets omfattning och kostnader har överenskommits skriftligt, behöver frilansaren tillgång till webbplatsen. Det betyder tillgång till frontend, CMS och analysverktyg och eventuellt en FTP-server. Så snart tillgång har beviljats, är SEO-experten anställd och arbetstiden börjar officiellt.</p>

                    <H as="h2">Hur blev jag en frilansare inom sökmotoroptimering?</H>
                    <p>Jag byggde min första privata hemsida vid 14 års ålder för att kunna dela min filmsamling med skolkompisar. I vår by var jag också den som reparerade datorer och gjorde många ominstallationer av operativsystem.</p>
                    <p>
                        När jag var 19 år byggde jag Kickerkult.de, en e-handelsbutik för bordfotboll och började med sökmotoroptimering för egen sökordsforskning. På den tiden räckte det nästan att optimera meta keywords och title tags.
                    </p>
                    <p>Att pyssla med webbplatser och teknik i allmänhet var alltid roligt för mig, så jag måste naturligt ha valt att arbeta heltid i webbyråer och online-marknadsföring senare. Kollegorna och projekten på dessa byråer var ett bra inflytande för att utöka mina färdigheter och göra mina arbetsflöden och processer mer professionella.</p>
                    <p>Efter att jag hade haft min beskärda del av SEO-jobb, blev framstegen tillsammans med en digital marknadsföringsbyrå allt svårare, så att frilansande SEO-rådgivning blev nästa logiska karriärsteg för mig.</p>
                    <p>De flesta <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">frilansare i Europa (39%) arbetar inom marknadsföring och kommunikation</a>. Jag ville gå djupare in i mitt ämne och kontrollera vilka projekt jag arbetar med, så frilansande blev nästa steg för att säkerställa att varje timme spenderas på en meningsfull uppgift.</p>
                    <p>Det visar sig att jag inte är helt ensam om den tanken. Faktum är att <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">frilansare eller iPros är den snabbast växande gruppen på EU:s arbetsmarknad sedan 2004</a> och <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">de står för cirka 7% av den totala arbetskraften i EU</a>.</p>
                    <p>Jag kan nu spendera mer tid på innehåll, sökordsforskning och länkbyggande, eftersom jag behöver mindre tid för administrativa uppgifter eller projektledning, medan mina kunder får mer faktiskt arbete för sin budget. Att frilansa på heltid ger mig därför en snabbare feedback-loop, vilket är nödvändigt för att konkurrera och vanligtvis inte uppnåeligt i en SEO-byrå.</p>
                    <p>Jag har arbetat som frilansande SEO-specialist sedan 2018. Jag arbetar mest med företagskunder som har multinationella webbplatser, samt e-handelsbutiker med en strategi driven av content marketing. Jag gör inte lokal SEO.</p>

                    <H as="h3">Behöver du SEO-hjälp?</H>
                    <p>
                        Om du letar efter en frilansande SEO-expert för att hjälpa dig med dina Google-rankningar, tveka inte att kontakta mig. Jag återkommer vanligtvis med en bedömning av situationen och en plan inklusive en offert.
                    </p>
                    <p>
                        Du kan annars hitta mer information på min <Link to="/se/om">om-sida</Link> och kanske några intressanta läsningar på min <Link to="/se/blogg">blogg</Link>.
                    </p>
                    <H as="h4" style={{ "textAlign": "center" }}>Kontakta mig för ett icke-bindande erbjudande.</H>
                </MainContent>
            </React.Fragment>

        </Layout>
    );
};

export default SeoFreelancer;
